<template>
  <div class="confirm">
    <el-form  ref="form" :model="form" label-width="100px">
      <div class="confirmInfo">请确认所示返修部件、返修地址及地址联系人！</div>
      <div class="col col4">
        <el-form-item label="返修地址" prop="repairAddress">
          <el-select v-model="form.repairAddress" @change="receivingAddresschange"  placeholder="请选择">
            <el-option v-for="(item,index) in repairOptions" :key="index" :label="item.label" :value="item.value"> </el-option>
          </el-select>
        </el-form-item>
      </div>
      <div class="col col4">
        <el-form-item label="库位" prop="storage" :rules="{ required: true, message: '请选择库位', trigger: 'change'}">
          <el-select  v-model="form.storage" filterable remote reserve-keyword
              value-key="id"
              @focus="clearDataTop"
              :remote-method="querySearch">
                <el-option
                  v-for='item in newSelect'
                  :key='item.id'
                  :label='item.lable'
                  :value='item'>
                </el-option>
              </el-select>
        </el-form-item>
      </div>
      <div class="col col4">
        <el-form-item label="RMA号">
            <el-input  v-model="form.rma " ></el-input>
        </el-form-item>
      </div>
      <div class="col col4">
        <el-form-item label="备注">
            <el-input  v-model="form.supplierBz "  ></el-input>
        </el-form-item>
      </div>
    </el-form>
  </div>
</template>

<script>
import { request } from '@/assets/js/http.js'
export default {
  name: 'ConfirmBtnDialog',
  data: function () {
    return {
      form: {
        repairAddress: '',
        storage: '',
        rma: '',
        supplierBz: ''
      },
      repairOptions: [],
      receivingAddress: [],
      Option: [],
      newSelect: []
    }
  },
  mounted () {
    this.receivingAddresschange()
  },
  methods: {
    querySearch (queryString) { // 库位
      var str = queryString.toString()
      // 匹配库位
      request('/api/livraison/entrepot/getEntrepotDataList?' + 'str=' + str, 'get').then((res) => {
        var newListEnt = []
        res.data.forEach(item => {
          newListEnt.push({
            lable: item.entrPosiName,
            id: item.id,
            storageType: item.entrPosiType,
            storageCode: item.entrPosiCode,
            entrPosiAddr: item.entrPosiAddr
          })
        })
        this.newSelect = newListEnt
      })
    },
    // 表头库存输入下拉框选中值以后，对应整列下拉框赋值
    // handleSelect (val) {
    //   this.form.bidScoringList.forEach(item => {
    //     item.ruleStorage = val
    //   })
    // },
    // 点击表头库位搜索时清空数据
    clearDataTop () {
      this.newSelect = []
    },
    receivingAddresschange (val) { // 返修地址
      request('/api/livraison/base/getBaseInfo', 'get').then((res) => {
        if (res.code === '200') {
          res.data.forEach(item => {
            this.repairOptions.push({
              label: item.baseName,
              value: item.baseCode
            })
          })
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/css/elform.scss';
.confirm{
.confirmInfo{
  margin: 20px;
  color: #FF6633;
}
// :deep(.el-input){
//   width: 60%;
// }
}

</style>
